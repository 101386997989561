import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Global from './services/global'

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

export default ({ component: Function, ...rest }) => {
    return (
        <Route {...rest} render={(props) => (
            Global.isAuthenticated
              ? <TheLayout {...props} />
              : <Redirect to='/login' />
          )} />
    );
};