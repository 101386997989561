import * as msal from "@azure/msal-browser"
import MSALConfig from '../config/MSALConfig';
import Global from "./global";

const msalConf = {
    auth: {
        clientId: MSALConfig.clientId,
        authority: "https://login.microsoftonline.com/"+MSALConfig.tenant
    },
    cache: {
        cacheLocation: MSALConfig.cacheLocation
    },
    system: {
        tokenRenewalOffsetSeconds: 1200
    }
    //tokenRenewalOffsetSeconds: 1200 //means 20 minutes
}

// let msalLoading = true;

const msalInstance = new msal.PublicClientApplication(msalConf);

msalInstance.handleRedirectPromise().then(res => {

    if (res) {
        // setting the Global isAuthenticated
        Global.setIsAuthenticated(res.account.username, res.accessToken, res.idToken);

        console.log('Redirecting to /');
        window.location = '/';
    } else {
        console.log('User not redirected');
    }    
})
.catch(error => {
    console.log('User redirected after login with below error');
    console.log(error);
  });


export default msalInstance;