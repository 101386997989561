import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import Global from './services/global'
import Auth from './services/Auth'

const checkCurrentUser = async () => {
  try {
    console.log("*** checkCurrentUser");
    var a = Auth.getAllAccounts();
      var res;
      if (a && a.length > 0) {
          const silentRequest = {
              account: a[0],
              scopes: ["Files.Read", "openid"]
          }
          res = await Auth.acquireTokenSilent(silentRequest);
          Global.setIsAuthenticated(res.account.username, res.accessToken, res.idToken);
          console.log("*** user is authenticated:", Global.email, res);
          //DashboardData.setData(LanesData, CarsData);
      }
  } catch(err)  {
      console.log('error in Index.checkCurrentUser ', err);
      console.log("*** user is not authenticated");
      Global.trackExceptionString("Unable to authenticate with error - " + err.message);
    }
  console.log("*** render");
  React.icons = icons

  ReactDOM.render(
    <Provider store={store}>
          <App/>
    </Provider>, 
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();

}

checkCurrentUser();



