/*global chrome*/
import Auth from '../services/Auth';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const Global = {
    email: '',
    authToken: '',
    isAuthenticated: false,
    user: {},
    auctionDate: '',
    previewWindow: null,
    manheimUserName: '',
    manheimPassword: '',
    hasValidManheimCredentials: false,
    setIsAuthenticated(email, authToken, idToken) {
        this.isAuthenticated = true;
        this.authToken = authToken;
        this.idToken = idToken;
        this.email = email;
        console.log("****** setIsAuthenticated called");
    },
    setUser(user) {
        this.user = user;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        this.auctionDate = yyyy + '-' + mm + '-' + dd;
    },
    getCurrentTimestamp() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        var hh = String(today.getHours()).padStart(2, '0');
        var min = String(today.getMinutes()).padStart(2, '0');
        var ss = String(today.getSeconds()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss;
    },
    getCurrentTimestampHumanFormat() {
        var mmmArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mmm = mmmArray[today.getMonth()];
        var yyyy = today.getFullYear();
        var hh = String(today.getHours()).padStart(2, '0');
        var ampm = "AM";
        if (today.getHours() >= 12) {
            ampm = "PM";
            if (today.getHours() > 12) hh = String(today.getHours()-12).padStart(2, '0');
        }
        var min = String(today.getMinutes()).padStart(2, '0');
        var ss = String(today.getSeconds()).padStart(2, '0');
        return mmm + ' ' + dd + ' ' + yyyy + ' ' +  hh + ':' + min + ':' + ss + ampm;
        //this should show up on the repo
    },
    toMoney(money) {
        if (Number.isInteger(money)) {
            return money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return money;
    },
    toPercent(numerator, denominator) {
        if (numerator && denominator) {
            if (Number.isInteger(numerator) && Number.isInteger(denominator)) {
                return Math.round(100.00 * numerator/denominator).toString() + '%';
            }    
        }
        return '';
    },
    toNumberWithCommas(x) {
        if (Number.isInteger(x)) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            if (typeof x == 'number') {
                //this is a float, get rid of the decimal point
                var xInt = Math.round(x);
                return xInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }
        return x;
    },
    toTimeAMPM(x) {
        //format: 2020-09-22T09:30:00
        var ampm = "AM";
        var hhS = x.substr(11, 2);
        var mmS = x.substr(14, 2);
        var hh = parseInt(hhS);

        if (Global.channel==="adesa") {
            var utcDate = x + '.000Z';
            var localDate = new Date(utcDate);
            hh = localDate.getHours();
            var m = localDate.getMinutes();
            mmS = m.toString();
            if (mmS.length === 1) mmS = mmS + "0";
        }

        //console.log("hhmm", hhS, mmS, x);

        if (hh > 12) {
            hh = hh-12;
            ampm = "PM";
        } else if (hh===12) {
            ampm = "PM";
        }
        return hh.toString() + ':' + mmS + ampm;
    },
    aadFunctionId: process.env.REACT_APP_FUNCTION_ID,
    manheimBaseUrl: process.env.REACT_APP_MANHEIM_BASE_URL,
    ExtensionId: "",
    isExtensionWS: false,
    previewUrl: process.env.REACT_APP_PREVIEW_URL,
    insightInstrumentationKey: process.env.REACT_APP_INSIGHT_INSTRUMENTATION_KEY,
    channel: process.env.REACT_APP_CHANNEL ? process.env.REACT_APP_CHANNEL : "manheim",
    MessageType: {
        START_LANE: 1,
        LOGIN_SUCCESFUL: 2,
        BID_INFO: 3,
        MINIMIZE_WINDOW: 4,
        LOGIN_REQUEST: 5,
        ANNOUNCEMENT: 6,
        SUBMIT_BID: 7,
        BID_INFO_UPDATE: 8,
        LANE_INACTIVE: 9,
        SHOW_LANE: 10,
        CLOSE_LANE: 11,
        AUCTION_SITE_CREDENTIALS: 12,
        REQUEST_AUCTION_SITE_CREDENTIALS: 13,
        CLOSE_WINDOW: 14,
        RUNLIST_VINS: 15,
        MATCHED_VINS: 16,
        NEW_CAR_IN_BLOCK: 17,
        DASHBOARD_IS_UP: 18,
        POPUP_ERROR: 19,
        ENABLE_SOUND: 20,
        DISABLE_SOUND: 21,
        INACTIVE: 22,
        WINDOW_GONE: 23,
        CHECK_CHILD: 24,
        CHECK_CHILD_RESPONSE: 25,
        POPUP_LOGOFF: 26,
        CLOSE_LOGOFF_LANE: 28,
        ALL_LANES: 32,
        REFRESH: 33,
        WEBSOCKET_STOPPED: 34,
        CHANNEL_INACTIVE: 92,
        CHANNEL_LOGGEDIN: 93,
        CHANNEL_LOGGEDOUT: 94,
        CHANNEL_ENDED: 95,
        RESTART_ADESA: 96,
        ADESA_PAGE_IS_GONE: 97,
        MISSING_HEARTBEAT: 98,
        HEARTBEAT: 99    
    },
    dashboardReadyCallback: null,
    async refreshToken() {
        try {
            console.log("*** checkCurrentUser");
            var a = Auth.getAllAccounts();
            var res;
            if (a && a.length > 0) {
                const silentRequest = {
                    account: a[0],
                    scopes: ["Files.Read", "openid"],
                    forceRefresh: true
                }
                res = await Auth.acquireTokenSilent(silentRequest);
                Global.idToken = res.idToken;
            } else {
                console.log("!!!!!!!!! WARNING: Auth.getAllAccounts() returns bad");
            }
        } catch(err)  {
              console.log('error in refresh token ', err);
              console.log("*** user is not authenticated");
              try {
                Global.trackExceptionString("unable to refresh the auth token, with error - " + err.message);
              } catch(err1) {
                console.log('error in refresh token, app insights logging ', err1);
              }
        }
        
    },

    reactPlugin: null,
    appInsights: null,
    appInsightsLoaded: false,
    loadAppInsights() {
        this.appInsightsLoaded = false;
        //if (this.appInsightsLoaded) return;

        try {
            const browserHistory = createBrowserHistory({ basename: 'Dashboard' });

            this.reactPlugin = new ReactPlugin();
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: this.insightInstrumentationKey,
                    extensions: [this.reactPlugin],
                    extensionConfig: {
                    [this.reactPlugin.identifier]: { history: browserHistory }
                    }
                }
            });
            this.appInsights.loadAppInsights();
            this.appInsightsLoaded = true;            
            console.log("*** app insights loaded");
        } catch(e) {
            console.log("!!! unable to load app insights");
        }        
    },
    trackEvent(name) {
        try {
            if (this.appInsightsLoaded) this.appInsights.trackEvent({name: name});
        } catch(err) {
            console.log("Error in submitting to app insights");
        }
    },
    trackPageView(page) {
        try {
            if (this.appInsightsLoaded) this.appInsights.trackPageView({name: page});
        } catch(err) {
            console.log("Error in submitting to app insights");
        }
    },
    trackException(exc) {
        try {
            if (this.appInsightsLoaded) this.appInsights.trackPageView({exception: exc});
        } catch(err) {
            console.log("Error in submitting to app insights");
        }
    },
    trackExceptionString(msg) {
        try {
            if (this.appInsightsLoaded) this.appInsights.trackException({exception: new Error("Critical error: " + msg + ", for user "  + this.email)});
        } catch(err) {
            console.log("Error in submitting to app insights");
        }
    },
    checkForExtensionErrors(txtFld) {
        if (txtFld.value && txtFld.value.length > 0) {
            this.trackExceptionString(txtFld.value);
            txtFld.value = "";
        }
    },
    browserVersion(){
        var ua= navigator.userAgent, tem, 
        M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    },
    sendToExtension(request) {
        request["extId"] = this.ExtensionId;
        console.log("sending request", request);
        chrome.runtime.sendMessage(this.ExtensionId, request);
    }
    
}
export default Global